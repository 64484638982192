import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
const ProductItem = ({ data, noWrap = false, demo = false }) => {
  const isBrowser = typeof window !== 'undefined';
  const navigate = (url: string) => {
    isBrowser && window.open(url, '_blank', 'noopener,noreferrer');
  };
  const { i18n, t } = useI18next();

  const requiLoginrender = data => {
    if (data.requireLogin) {
      switch (data.requireLogin.type) {
        case 0:
          return (
            <p>
              {t('user_and_pass')}: {t('hrgs_account')}
            </p>
          );
        case 1:
          return (
            <p>
              {t('user_and_pass')}: {t('cube_domain')}
            </p>
          );
        case 2:
          return (
            <p>
              {t('user_pass', {
                user: t(data.requireLogin.user),
                pass: data.requireLogin.pass,
              })}
            </p>
          );
        case 3:
          return (
            <p>
              {t('user_pass', {
                user: data.requireLogin.user,
                pass: data.requireLogin.pass,
              })}
            </p>
          );
        default:
          return <p>{t('user') + '/' + t('pass') + ': ' + t('no_need')}</p>;
      }
    }
    return <p>{t('user') + '/' + t('pass') + ': ' + t('no_need')}</p>;
  };

  return (
    <div
      className={`${'pro-item'} relative`}
      style={{}}
      onClick={() => navigate(data.url)}
    >
      <div
        style={{
          // background: '#fff',
          width: '100%',
          height: '100%',
          // border: '1px solid #000',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'start',
          flexDirection: 'column',
          position: 'relative',
          padding: 12,
        }}
      >
        <div
          className={`pro-content relative ${demo && 'overflow-hidden'} w-full`}
        >
          {demo && (
            <div className="absolute z-20 w-[100px] bg-[#fac] flex justify-center demo-banner s-irregularShadowMove">
              <span> {t('demo')} </span>
            </div>
          )}
          <div className="flex items-center">
            <img
              src={`${window.location.origin}/assets/svg/products/${data.logo}`}
              style={{
                objectFit: 'contain',
                width: 100,
                height: 80,
              }}
              className={`m-0 ${!demo && 'implemented'}`}
            />

            <span className="title-item flex-1">{data.name}</span>
          </div>

          <div
            className="h-[60px] "
            // style={{
            //   overflow: 'hidden',
            //   textOverflow: 'ellipsis',
            //   whiteSpace: 'nowrap',
            //   width: '100%',
            // }}
          >
            <p className="text-box">{data['desc_' + i18n.resolvedLanguage]}</p>
          </div>
          {requiLoginrender(data)}

          <div className="frameworks-logo">
            {data.frameworks &&
              data.frameworks.map(framework => (
                <img
                  key={framework}
                  src={`${window.location.origin}/assets/svg/frameworks/${framework}.svg`}
                />
              ))}
          </div>
          <div className="flex items-end">
            <div className="flex flex-1 flex-col">
              <div className="pro-type">
                {data.type &&
                  data.type.map(type => <span key={type}>{type}</span>)}
              </div>
            </div>
            <button
              onClick={() => navigate(data.url)}
              className="outline-button"
            >
              {t('open')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductItem;
