const listReadyProducts = [
  {
    logo: 'HRGs_v1.png',
    name: 'HRGs',
    desc: `Human resource management system, timekeeping, contract, salary calculation for the organization, integrating a variety of timekeeping methods`,
    desc_en:
      'Human resource management system, timekeeping, contract, salary calculation for the organization, integrating a variety of timekeeping methods',
    desc_ja:
      '組織の人事管理システム、打刻、契約、給与計算など、さまざまな打刻方法を統合',
    desc_vi:
      'Hệ thống quản lý nhân sự, chấm công, hợp đồng, tính lương cho tổ chức. Tích hợp nhiều phương pháp chấm công',
    type: ['Web app'],
    frameworks: ['php'],
    url: 'https://cubevn.hrgs-csv.com/',
    status: 'implementing',
    scope: [],
    requireLogin: {
      type: 0,
    },
  },
  {
    logo: 'device_manager.svg',
    name: 'Device Manager',
    desc: `Device management integrates web and mobile app systems, making inventory and management of office equipment easy, quick and cost-effective.`,
    desc_en:
      '"Device Manager" is integrated with web system and mobile application, making inventory and management of office equipment easy, fast and cost-effective.',
    desc_ja:
      '「デバイス マネージャー」は Web システムとモバイル アプリケーションと統合されており、オフィス機器の在庫管理と管理を簡単、迅速、コスト効率よく行うことができます。',
    desc_vi:
      '“Device Manager” được tích hợp với hệ thống web và ứng dụng di động, giúp việc kiểm kê và quản lý thiết bị văn phòng trở nên dễ dàng, nhanh chóng và tiết kiệm chi phí.',
    type: ['Web app', 'Mobile app'],
    frameworks: ['codeigniter', 'flutter'],
    status: 'implementing',
    url: 'https://devicemanager.hrgs-csv.com/',
    scope: [],
    requireLogin: {
      type: 0,
    },
  },
  {
    logo: 'cbook.svg',
    name: 'C-BOOK',
    desc: `Synthesize and share topics, articles about information, policies, and events from the company.`,
    desc_en: `Synthesize and share topics, articles about information, policies, and events from the company`,
    desc_ja: `組織、会社からのトピック、情報、ポリシー、イベントに関する記事を統合して共有します`,
    desc_vi: `Tổng hợp và chia sẻ các chủ đề, bài viết về thông tin, chính sách, sự kiện của tổ chức, công ty`,
    type: ['Web app'],
    frameworks: ['vue', 'golang'],
    url: 'https://cbook.cubevn-service.com/',
    status: 'implementing',
    scope: ['administrator'],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'doc.svg',
    name: 'Cloud Documents',
    type: ['Web app'],
    desc: `A safe and friendly system for storing all data on Cloud.`,
    desc_en: `A safe and friendly system for storing all data on Cloud.`,
    desc_ja: `すべてのデータをクラウド上に保存するための安全でフレンドリーなシステム。`,
    desc_vi: `Một hệ thống an toàn và thân thiện để lưu trữ tất cả dữ liệu của bạn trên đám mây.`,
    frameworks: ['nextcloud'],
    status: 'implementing',
    url: 'https://doc.cubevn-service.com/',
    scope: ['administrator'],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'affine.svg',
    name: 'AFFiNE',
    desc: `AFFiNE`,
    desc_en: `AFFiNE: A workspace with fully merged docs, whiteboards and databases. Get more things done, your creativity isn’t monotone.`,
    desc_ja:
      'AFFiNEシステム：完全に統合されたドキュメント、ホワイトボード、データベースを備えたワークスペース。 より多くのことを成し遂げましょう。あなたの創造力は単調なものではありません。',
    desc_vi:
      'AFFiNE: A workspace with fully merged docs, whiteboards and databases. Get more things done, your creativity isn’t monotone.',
    type: ['Web app'],
    frameworks: ['graphql', 'nextjs'],
    status: 'implementing',
    url: 'https://affine.cubevn-service.com/',
    scope: ['administrator'],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'faas.png',
    name: '会議室予約',
    desc: `Ứng dụng quản lý và hỗ trợ đặt phòng họp. Có các phiên bản mobile và web.`,
    desc_en:
      'Application for management and metting room booking support. Supporting platform: web and mobile.',
    desc_ja:
      '会議室の予約を管理およびサポートするアプリケーション。 モバイル版とウェブ版があります。',
    desc_vi:
      'Ứng dụng quản lý và hỗ trợ đặt phòng họp. có các phiên bản mobile và web.',
    type: ['Web app'],
    frameworks: ['angular', 'nodejs'],
    status: 'implementing',
    url: 'http://meeting-room-booking.cubevn-service.com/',
    scope: [],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'gpt.svg',
    name: 'Chat GPT',
    desc: `Chat GPT`,
    desc_en: 'Chat GPT',
    desc_ja: 'Chat GPT',
    desc_vi: 'Chat GPT',
    type: ['Web app', 'Services'],
    status: 'implementing',
    url: 'https://c-gpt.cubevn-service.com/chat/',
    scope: ['administrator'],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'prm.png',
    name: 'Project Resource Management',
    desc: `Effective project and human resource management system with optimized operations.`,
    desc_en:
      'Effective project and human resource management system with optimized operations.',
    desc_ja: '運用を最適化した効果的なプロジェクト・人材管理システム。',
    desc_vi:
      'Hệ thống quản lý dự án và nguồn nhân lực hiệu quả với việc tối ưu hóa thao tác.',
    type: ['Web app'],
    frameworks: ['vue', 'nodejs'],
    status: 'implementing',
    url: 'https://prm.cubevn-service.com/',
    scope: ['administrator'],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'hyouka-v2.svg',
    name: 'HYOUKA VERSION 2',
    desc: `Create an individual performance evaluation form during work`,
    desc_en: `Create a form to evaluate personal achievements during work`,
    desc_ja: `仕事中の個人の成果を評価するフォームを作成する`,
    desc_vi: `Tạo form đánh giá thành tích cá nhân trong quá trình làm việc.`,
    type: ['Web app'],
    frameworks: ['react_logo'],
    status: 'implementing',
    url: 'https://hyouka-v2.cubevn-service.com/',
    scope: [],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'cnetpass.svg',
    name: 'C - NETPASS',
    desc: `Now, it is easily to easily change your network account password without going through the IT helpdesk.`,
    desc_en:
      'Now, it is easily to easily change your internal network account password without going through the IT helpdesk',
    desc_ja:
      'IT ヘルプデスクを経由せずに、内部ネットワーク アカウントのパスワードを簡単に変更できるようになりました。',
    desc_vi:
      'Giờ đây, bạn có thể dễ dàng thay đổi mật khẩu tài khoản mạng nội bộ của mình mà không cần thông qua bộ phận IT Helpdesk',
    type: ['Web app'],
    frameworks: ['php'],
    status: 'implementing',
    url: 'https://changepassword.vn-cubesystem.com/',
    scope: [],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'skillmap.svg',
    name: 'Skillmap',
    desc: `Skillmap: Retrieve, Update CSV Staffs' skills`,
    desc_en: `Skillmap: Retrieve, Update CSV Staffs' skills`,
    desc_ja: 'Skillmapシステム：CSV スタッフのスキルの取得、更新',
    desc_vi:
      'Skillmap: Truy vấn, cập nhật thông tin Skill của nhân viên CUBE SYSTEM',
    type: ['Web app'],
    frameworks: ['vue', 'golang'],
    status: 'implementing',
    url: 'https://skillmap.cubevn-service.com/',
    scope: ['administrator'],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'security-check.png',
    name: 'Security Check',
    desc: `Security Check System`,
    desc_en: 'Security Check System',
    desc_ja: 'セキュリティチェックシステム',
    desc_vi: 'Security Check System',
    type: ['Web app'],
    frameworks: ['vue', 'nodejs'],
    status: 'implementing',
    url: 'http://securitycheck.cubevn-service.com/',
    scope: [],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'kintone.png',
    name: 'CSV KINTONE',
    desc: `An organization's data management software is developed without the use of code`,
    desc_en:
      "An organization's data management software is developed without the use of code",
    desc_ja: '組織のデータ管理ソフトウェアはコードを使用せずに開発されています',
    desc_vi:
      'Phần mềm quản lý dữ liệu của tổ chức được phát triển mà không cần sử dụng code',
    type: ['Web app'],
    frameworks: ['java'],
    status: 'implementing',
    url: 'https://cubesystemvietnam.cybozu.com/k/#/portal',
    scope: ['administrator'],
  },
  {
    logo: 'sso.svg',
    name: 'SSO (Sigle Sign Only)',
    desc: `SSO (Sigle Sign Only) integration system for logging in to CubeSystem Vietnam's product systems`,
    desc_en:
      "SSO (Sigle Sign Only) integration system for logging into CubeSystem Vietnam's products system",
    desc_ja:
      'CubeSystem Vietnam の製品システムにログインするための SSO (Sigle Sign Only) 統合システム',
    desc_vi:
      'Hệ thống tích hợp SSO (Sigle Sign Only) để đăng nhập vào hệ thống các sản phẩm của CubeSystem Việt Nam',
    type: ['Web app'],
    frameworks: ['react_logo'],
    status: 'implementing',
    url: 'https://sso.cubevn-service.com/admin/csv/console/',
    scope: ['administrator'],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'aws.svg',
    name: 'AWS Resources',
    desc: `A managed system that provides roles to access AWS Resources of CUBE SYSTEM VIETNAM`,
    desc_en:
      'A managed system that provides roles to access AWS resources of CUBE SYSTEM VIETNAM',
    desc_ja:
      'CUBE SYSTEM VIETNAM の AWS リソースにアクセスするためのロールを提供するマネージド システム',
    desc_vi:
      'Một hệ thống quản lý cung cấp các vai trò để truy cập tài nguyên AWS của CUBE SYSTEM VIETNAM',
    type: ['Web app', 'Tool'],
    frameworks: ['aws'],
    status: 'implementing',
    url: 'https://aws.cubevn-service.com/',
    scope: [],
  },
  {
    logo: 'gitlab.png',
    name: 'Gitlab',
    desc: `Ứng dụng quản lý git và triển khai CICD`,
    desc_en: 'Application for management of git and providing CICD tools',
    desc_ja: 'Application for management of git and providing CICD tools',
    desc_vi: 'git の管理と CICD ツールの提供のためのアプリケーション',
    type: ['Web app'],
    frameworks: ['ruby', 'golang'],
    status: 'implementing',
    url: 'http://gitlab.cubevn-service.com/',
    scope: ['administrator'],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'sharefile.svg',
    name: 'Share File',
    desc: `Share File: sharing files in local network or public`,
    desc_en: `Share File: sharing files in local network or public`,
    desc_ja:
      'ShareFileシステム：ローカルネットワークまたはパブリックでファイルを共有',
    desc_vi: 'Share File: Chia sẻ file trong mạng nội bộ hoặc public',
    type: ['Web app'],
    frameworks: ['react_logo', 'nodejs'],
    status: 'implementing',
    url: 'https://sharing.cubevn-service.com/',
    scope: ['administrator'],
  },
  {
    logo: 'seatchart.png',
    name: 'Seat Chart',
    desc: `The Seat Chart system to arrange seating efficiently and provide an overview, meeting the demands of continuous changes and expanding scale.`,
    desc_en:
      'The Seat Chart system to arrange seating efficiently and provide an overview, meeting the demands of continuous changes and expanding scale.',
    desc_ja:
      'SeatChartシステムは、効率的な座席配置と全体の把握を提供し、継続的な変更と拡大する規模の要求に応えます。',
    desc_vi:
      'Hệ thống Seat Chart để sắp xếp chỗ ngồi hiệu quả và có cái nhìn tổng quan, đáp ứng nhu cầu thay đổi liên tục và mở rộng quy mô.',
    type: ['Web app'],
    frameworks: ['vue', 'lambda_function'],
    status: 'implementing',
    url: 'https://seatchart.cubevn-service.com/',
    scope: [],
    requireLogin: {
      type: 1,
    },
  },
  // DEMO
  {
    logo: 'tanaoroshi.svg',
    name: 'Taro App',
    desc: `Inventory system, storing goods information, integrating collection of goods information by mobile application`,
    desc_en:
      'Inventory system, storing goods information, integrating collection of goods information with mobile application',
    desc_ja:
      '在庫システム、商品情報の保管、商品情報の収集とモバイルアプリケーションの統合',
    desc_vi:
      'Hệ thống kiểm kho, lưu trữ thông tin hàng hóa, tích hợp thu thập thông tin hàng hóa bằng ứng dụng di động',
    type: ['Web app', 'Mobile app'],
    frameworks: ['vue', 'golang', 'flutter'],
    status: 'demo',
    url: 'https://tanaoroshi.csvdemo.com/',
    scope: ['administrator'],
    requireLogin: {
      type: 3,
      user: 'cubesystem',
      pass: '1234567',
    },
  },
  {
    logo: 'device-up.svg',
    name: 'Device Manager Up Version',
    desc: `Device management integrates web and mobile app systems, making inventory and management of office equipment easy, quick and cost-effective.`,
    desc_en:
      '"Device Manager" is integrated with web system and mobile application, making inventory and management of office equipment easy, fast and cost-effective.',
    desc_ja:
      '「デバイス マネージャー」は Web システムとモバイル アプリケーションと統合されており、オフィス機器の在庫管理と管理を簡単、迅速、コスト効率よく行うことができます。',
    desc_vi:
      '“Device Manager” được tích hợp với hệ thống web và ứng dụng di động, giúp việc kiểm kê và quản lý thiết bị văn phòng trở nên dễ dàng, nhanh chóng và tiết kiệm chi phí.',
    type: ['Web app', 'Mobile app'],
    frameworks: ['vue', 'golang', 'flutter'],
    status: 'demo',
    url: 'https://device.csvdemo.com/',
    scope: ['administrator'],
    requireLogin: {
      type: 2,
      user: 'hrgs_account',
      pass: '1',
    },
  },
  {
    logo: 'survey.svg',
    name: 'Survey',
    desc: `Create a form to collect and survey people's opinions, which can be integrated as a mini game for groups`,
    desc_en:
      'Create a form to collect and survey, which can be integrated as a mini game ',
    desc_ja: 'ミニゲームとして組み込める収集・アンケートフォームの作成',
    desc_vi:
      'Tạo form thu thập và khảo sát ý kiến, có thể tích hợp dưới dạng mini game',
    type: ['Web app'],
    frameworks: ['vue', 'golang'],
    status: 'demo',
    url: 'https://survey.csvdemo.com/',
    scope: ['administrator'],
    requireLogin: {
      type: 0,
    },
  },
  {
    logo: 'ocr.svg',
    name: 'Optical character recognition',
    desc: `Application that scans handwriting format using AI artificial intelligence.`,
    desc_en: 'Application to scan and recognize text, handwriting',
    desc_ja: 'テキストや手書きをスキャンして認識するアプリケーション',
    desc_vi: 'Ứng dụng quét và nhận dạng văn bản, chữ viết tay',
    type: ['Web app', 'Tool'],
    frameworks: ['react_logo'],
    status: 'demo',
    url: 'https://ocr-cv.csvdemo.com/',
    scope: ['administrator'],
  },
  {
    logo: 'ai-xray.svg',
    name: 'AI XRAY',
    desc: `Application that scans handwriting format using AI artificial intelligence.`,
    desc_en:
      "The application analyzes x-ray images of the teeth's position using AI",
    desc_ja: 'X線画像で歯の位置をAIで解析するアプリ',
    desc_vi: 'Ứng dụng phân tích hình ảnh x-quang về vị trí của răng bằng AI',
    type: ['Web app', 'Tool'],
    frameworks: ['vue', 'python'],
    status: 'demo',
    url: 'https://ai-xray.csvdemo.com/',
    scope: ['administrator'],
  },
  {
    logo: 'keibi.svg',
    name: 'Keibi',
    desc: `Management system, tracking security guards, recording employee's working location history.`,
    desc_en:
      'Management system, tracking security guards, recording employees working location history',
    desc_ja: '管理システム、警備員の追跡、従業員の勤務場所履歴の記録',
    desc_vi:
      'Hệ thống quản lý, theo dõi nhân viên bảo vệ, ghi lại lịch sử địa điểm làm việc của nhân viên',
    type: ['Web app', 'Mobile ap'],
    frameworks: ['vue', 'golang', 'flutter'],
    status: 'demo',
    url: 'https://keibi.csvdemo.com/',
    scope: ['administrator'],
    requireLogin: {
      type: 3,
      user: 'csvadmin',
      pass: 'csvx',
    },
  },
  {
    logo: 'threejs.svg',
    name: 'Three JS 3D',
    desc: `3d model on web platform using Three JS library`,
    desc_en: 'Demo 3d model on web platform using Three JS library',
    desc_ja:
      'Three JS ライブラリを使用した Web プラットフォーム上の 3D モデルのデモ',
    desc_vi: 'Demo mô hình 3d trên nền tảng web sử dụng thư viện Three JS',
    type: ['Web app'],
    frameworks: ['react_logo'],
    status: 'demo',
    url: 'https://global8-3d.csvdemo.com/',
    scope: ['administrator'],
  },
  {
    logo: 'bealog.svg',
    name: 'Bealog',
    desc: `Bealog system: receive information from Beacons`,
    desc_en: 'Bealog system: receive information from Beacons',
    desc_ja: 'BEALOGシステム：ビーコンから情報を受け取る',
    desc_vi: 'Bealog: nhận thông tin từ hệ thống Beacons',
    type: ['Web app'],
    frameworks: ['react_logo', 'nodejs'],
    status: 'demo',
    url: 'https://bealog.csvdemo.com/',
    scope: ['administrator'],
  },
  {
    logo: 'hyouka.svg',
    name: 'HYOUKA',
    desc: `Create an individual performance evaluation form during work`,
    desc_en: `Demo Create a form to evaluate personal achievements during work`,
    desc_ja: `仕事中の個人の成果を評価するフォームを作成するデモ`,
    desc_vi: `Demo Tạo form đánh giá thành tích cá nhân trong quá trình làm việc.`,
    type: ['Web app'],
    frameworks: ['vue', 'golang'],
    status: 'demo',
    url: 'https://hyouka.csvdemo.com/',
    scope: [],
    requireLogin: {
      type: 1,
    },
  },
  {
    logo: 'wfh.svg',
    name: 'Work From Home',
    desc: `System for registration and confirmation of working from home for company employees`,
    desc_en:
      'Deni System for registration and confirmation of working from home for company employees',
    desc_ja: '会社員向け在宅勤務登録・確認システムデモ',
    desc_vi:
      'Demo Hệ thống đăng ký và xác nhận làm việc tại nhà cho nhân viên công ty',
    type: ['Web app'],
    frameworks: ['vue', 'golang'],
    status: 'demo',
    url: 'https://wfh.cubevn-service.com/login',
    scope: [],
    requireLogin: {
      type: 0,
    },
  },
  {
    logo: 'cmeet.svg',
    name: 'C-MEET',
    desc: 'The application uses WebRTC to make video calls, create online meeting rooms, and integrate team member tracking.',
    desc_en:
      "The application uses WebRTC to make video calls, create online meeting rooms, and integrate member's cursor tracking in room",
    desc_ja:
      'このアプリケーションは WebRTC を使用してビデオ通話を行い、オンライン会議室を作成し、部屋内のメンバーのカーソル追跡を統合します。',
    desc_vi:
      'Ứng dụng sử dụng WebRTC để gọi video, tạo phòng họp trực tuyến, tích hợp theo dõi con trỏ của thành viên trong phòng',
    type: ['Web app'],
    frameworks: ['react_logo', 'webrtc'],
    status: 'demo',
    url: 'https://c-meet.csvdemo.com/',
    scope: ['administrator'],
  },
];

export { listReadyProducts };
