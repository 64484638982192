import React, { useRef, useState } from 'react';
import { useFrame, useLoader, extend } from '@react-three/fiber';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { useGLTF } from '@react-three/drei';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import fontJSON from '../../static/assets/font/gentilis_bold.typeface.json';
extend({ TextGeometry });
function HeroContent(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef();
  const ref = useRef<any>();
  const ref2 = useRef<any>();
  const ref3 = useRef<any>();
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);
  // Subscribe this component to the render-loop, rotate the mesh every frame
  //   useFrame((state, delta) => (mesh.current.rotation.x += delta));
  // Return view, these are regular three.js elements expressed in JSX
  const group = useRef<any>();
  const points = useRef();
  const gltfModel = useGLTF(`../../assets/3d/cube3d7.gltf`) as any;
  // parse JSON file with Three
  //   const font = new THREE.FontLoader().parse(Roboto);
  let loader = new FontLoader();
  let font = loader.parse(fontJSON);
  useFrame((state, delta) => {
    ref.current.rotation.x += 0.01;
    ref2.current.rotation.x += 0.02;
    ref3.current.rotation.y += 0.01;
  });
  return (
    <group {...props} dispose={null} scale={1}>
      <mesh
        geometry={gltfModel.nodes.Circle.geometry}
        material={gltfModel.materials['Material.004']}
        position={[0, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={3}
      />
      <mesh
        geometry={gltfModel.nodes.C.geometry}
        material={gltfModel.materials['Material.003']}
        position={[-1.36, -1.12, 1.8]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={5.2}
      />
      <mesh position={[-1.36, 3, 2]} ref={ref}>
        <boxGeometry args={[0.3, 0.3, 0.3]} />
        <meshStandardMaterial color={'#fac'} />
      </mesh>
      <mesh position={[2, 3.6, -0.4]} ref={ref2}>
        <torusGeometry args={[0.2, 0.1, 10, 30]} />
        <meshStandardMaterial color={'#F79327'} />
      </mesh>
      <mesh position={[3, -2, 1]} ref={ref3}>
        <boxGeometry args={[0.3, 0.3, 0.3]} />
        <meshStandardMaterial color={'#00DFA2'} />
      </mesh>
    </group>
  );
}
export default HeroContent;
