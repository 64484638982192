const products = [
  {
    id: 'hrgs',
    title: 'HRGS',
    url: 'https://cubevn.csvdemo.com',
    img: '/assets/svg/products/HRGs_v1.png',
    scope: ['administrator'],
  },
  {
    id: 'device',
    title: 'Device Manager',
    url: 'https://devicemanager.csvdemo.com',
    img: '/assets/svg/products/device_manager.svg',
    scope: ['administrator'],
  },
  {
    id: 'hyouka',
    title: 'Hyouka',
    url: 'https://hyouka.csvdemo.com',
    img: '/assets/svg/products/hyouka.svg',
    scope: ['administrator'],
  },
  {
    id: 'keibi',
    title: 'Keibi',
    url: 'https://keibi.csvdemo.com',
    img: '/assets/svg/products/keibi.svg',
    scope: ['administrator'],
  },
  {
    id: 'tanaoroshi',
    title: 'Tanaoroshi',
    url: 'https://tanaoroshi.csvdemo.com',
    img: '/assets/svg/products/tanaoroshi.svg',
    scope: ['administrator'],
  },
  {
    id: 'ocr',
    title: 'OCR CV',
    url: 'https://ocr-cv.csvdemo.com',
    img: '/assets/svg/products/ocr.svg',
    scope: ['administrator'],
  },
  {
    id: 'sso',
    title: 'SSO',
    url: 'https://sso.cubevn-service.com',
    img: '/assets/svg/products/sso.svg',
    scope: ['administrator'],
  },
  {
    id: 'Survey',
    title: 'Survey',
    url: 'https://survey.csvdemo.com',
    img: '/assets/svg/products/survey.svg',
    scope: ['administrator'],
  },
  {
    id: 'doc',
    title: 'Document system',
    url: 'https://doc.csvdemo.com',
    img: '/assets/svg/products/doc.svg',
    scope: ['administrator'],
  },
  {
    id: 'call',
    title: 'Video call',
    url: 'https://call.csvdemo.com',
    img: '/assets/svg/products/call.svg',
    scope: ['administrator'],
  },
  {
    id: 'aws',
    title: 'CSV AWS',
    url: 'https://aws.cubevn-service.com',
    img: '/assets/svg/products/aws.svg',
    scope: ['administrator'],
  },
  {
    id: '3d',
    title: '3D ThreeJS',
    url: 'https://global8-3d.csvdemo.com/',
    img: '/assets/svg/products/threejs.svg',
    scope: ['administrator'],
  },
  {
    id: 'ai-xray',
    title: 'AI Xray',
    url: 'https://ai-xray.csvdemo.com//',
    img: '/assets/svg/products/ai-xray.svg',
    scope: ['administrator'],
  },
  {
    id: 'device-up',
    title: 'Device Version Up',
    url: 'https://device.csvdemo.com//',
    img: '/assets/svg/products/device-up.svg',
    scope: ['administrator'],
  },
  {
    id: 'wfh',
    title: 'Work From Home',
    url: 'https://wfh.csvdemo.com/',
    img: '/assets/svg/products/wfh.svg',
    scope: ['administrator'],
  },
  {
    id: 'cbook',
    title: 'CUBE BOOK',
    url: 'https://cbook.csvdemo.com/',
    img: '/assets/svg/products/cbook.svg',
    scope: ['administrator'],
  },
  {
    id: 'devops-portal',
    title: 'DevOps Portal',
    url: 'https://c-gpt.csvdemo.com/chat/',
    img: '/assets/svg/products/portal.svg',
    scope: ['administrator'],
  },
];

export default products;
