import React, { useState } from 'react';

import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { useMemo, useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import { gsap } from 'gsap';
import { useKeycloak } from '@react-keycloak/web';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from '../components/layout';
import productsPrd from '../mocks/productions.prd';
import productsDev from '../mocks/productions.dev';
import HeroContent from '../components/heroContent';
import ProductItem from '../components/productItem';
import FlutterLogo from '../../static/assets/svg/frameworks/flutter.svg';
import ReactLogo from '../../static/assets/svg/frameworks/react_logo.svg';
import GolangLogo from '../../static/assets/svg/frameworks/golang.svg';
import WebRTCLogo from '../../static/assets/svg/frameworks/webrtc.svg';
import NextCloudLogo from '../../static/assets/svg/frameworks/nextcloud.svg';
import VueLogo from '../../static/assets/svg/frameworks/vue.svg';
import AwsLogo from '../../static/assets/svg/frameworks/aws.svg';
import NodejsLogo from '../../static/assets/svg/frameworks/nodejs.svg';
import SwiftLogo from '../../static/assets/svg/frameworks/swift.svg';
import KotlinLogo from '../../static/assets/svg/frameworks/kotlin.svg';
import AndroidLogo from '../../static/assets/svg/frameworks/android.svg';
import IosLogo from '../../static/assets/svg/frameworks/ios.svg';
import PythonLogo from '../../static/assets/svg/frameworks/python.svg';
import HtmlLogo from '../../static/assets/svg/frameworks/html.svg';
import CssLogo from '../../static/assets/svg/frameworks/css3.svg';
import MysqlLogo from '../../static/assets/svg/frameworks/mysql.svg';
import PostgreLogo from '../../static/assets/svg/frameworks/postgresql.svg';
import MouseScroll from '../../static/assets/svg/frameworks/mouse_down.svg';
import { listReadyProducts } from '../mocks/products';
import '../../static/assets/styles/feature/cursor.scss';
import Seo from '../components/seo';
gsap.registerPlugin(ScrollTrigger);

const deg2rad = degrees => degrees * (Math.PI / 180);
const products =
  process.env.GATSBY_APP_ENV === 'production' ? productsPrd : productsDev;

const initialState = {
  page: 0,
  perPage: 5,
  totalPage: Math.ceil(products.length / 5),
  data: products,
};

const Scene = () => {
  // let camera = PerspectiveCamera(90, 1.5, 0.1, 1000);
  const mesh = useRef();
  const scenRef = useRef<any>();
  const [sceneHeight, setScenHeight] = useState(0);
  const resize = () => {
    setTimeout(() => {
      setScenHeight(scenRef.current.offsetWidth + 230);
    }, 250);
  };
  useEffect(() => {
    setScenHeight(scenRef.current.offsetWidth + 230);
    window.addEventListener('resize', resize);
  }, [scenRef]);
  React.useEffect(() => {
    // dispatch it once mounted
    window.dispatchEvent(new Event('resize'));
  }, []);
  return (
    <div
      ref={scenRef}
      className="canvas w-full h-full"
      style={{ height: sceneHeight }}
    >
      <Canvas camera={{ position: [8, 0.7, 2.6] }}>
        <ambientLight intensity={0.2} />
        <pointLight position={[-10, 160, 3]} power={14} />
        <pointLight position={[5, 8, 4]} power={10} />
        <pointLight position={[1, 30, 2]} />
        <mesh>
          <OrbitControls
            minAzimuthAngle={-Math.PI / 10}
            maxAzimuthAngle={Math.PI / 10}
            minPolarAngle={Math.PI / 30}
            maxPolarAngle={Math.PI - Math.PI / 30}
            enableZoom={false}
          />
          <HeroContent position={[0, 0, 0]} rotation={[-0.3, -0.3, -0.18]} />
        </mesh>
      </Canvas>
    </div>
  );
};

const IndexPage = () => {
  const [state, dispatch] = React.useReducer(
    (currSt, newSt) => ({ ...currSt, ...newSt }),
    initialState
  );

  const comp = useRef<HTMLDivElement>(null);
  const pro = useRef<HTMLDivElement>(null);
  const { page, totalPage, data, perPage } = state;
  const { keycloak } = useKeycloak();
  const [user, setUser] = useState<any>(null);
  const productsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      // responsive title hero large
      if (window.innerWidth >= 768) {
        gsap.to('.title-hero', { x: '8%', direction: 1 });
      }
      // scale hero title
      gsap.to('.session-hero', {
        scale: 0.8,
        direction: 0.5,
        scrollTrigger: {
          trigger: pro.current,
          start: 'top 80%',
          end: 'top 100px',
          // markers: true,
          toggleActions: 'restart none none reverse',
          // onEnter: () => console.log('adadasd'),
          // pin: '.session-start',
        },
      });
      // scale hero title
      gsap.from('.prolist-1', {
        y: 100,
        opacity: 0,
        direction: 2,
        scrollTrigger: {
          trigger: '.prolist-1',
          start: 'top bottom',
          end: 'top 100px',
          // markers: true,
          toggleActions: 'restart none none reverse',
          // onEnter: () => console.log('adadasd'),
          // pin: '.session-start',
        },
      });
      gsap.from('.prolist-2', {
        y: 100,
        opacity: 0,
        direction: 2,
        scrollTrigger: {
          trigger: '.prolist-2',
          start: 'top bottom',
          end: 'top 100px',
          // markers: true,
          toggleActions: 'restart none none reverse',
          // onEnter: () => console.log('adadasd'),
          // pin: '.session-start',
        },
      });
      // pin hero session
      ScrollTrigger.create({
        trigger: '.session-start',
        start: 'top top',
        end: 'max',
        pin: true,
        pinSpacing: false,
      });
    }, comp);
    return () => ctx.revert();
  }, [keycloak.authenticated]);

  React.useEffect(() => {
    const loadUser = async () => {
      if (keycloak.authenticated) {
        await keycloak.loadUserInfo();
        //Set token in the localStorage
        localStorage.setItem('keycloak-sso-token', keycloak.token ?? '');
        setUser(keycloak.userInfo);
      }
    };
    loadUser();
  }, [keycloak.authenticated]);

  // render implementing products with roles
  const implementingProductsRender = () => {
    const filteredData = listReadyProducts.filter(item => {
      if (item.status != 'implementing') return false;
      if (item.scope.length === 0) return true;
      else {
        const roles = user?.resource_access?.dashboard?.roles as never[];
        if (roles) {
          for (const role of roles) {
            if (item.scope.indexOf(role) >= 0) return true;
          }
        }
        return false;
      }
    });
    if (filteredData.length > 0)
      return (
        <>
          {filteredData.map(i => (
            <ProductItem key={i.logo} data={i} />
          ))}
        </>
      );
    return (
      <div className="flex justify-center w-full">Products not found!</div>
    );
  };

  // render demo products with roles
  const demoProductsRender = () => {
    const filteredData = listReadyProducts.filter(item => {
      if (item.status != 'demo') return false;
      if (item.scope.length === 0) return true;
      else {
        const roles = user?.resource_access?.dashboard?.roles as never[];
        if (roles) {
          for (let role of roles) {
            if (item.scope.indexOf(role) >= 0) return true;
          }
        }
        return false;
      }
    });
    if (filteredData.length > 0)
      return (
        <>
          {filteredData.map(i => (
            <ProductItem key={i.logo} data={i} demo={true} />
          ))}
        </>
      );
    return (
      <div className="flex justify-center w-full">Products not found!</div>
    );
  };
  const { t } = useTranslation();
  if (keycloak.authenticated)
    return (
      <>
        <Seo title="Home" children={null} />
        <Layout user={user}>
          <div
            style={{
              background: '#fff',
              backgroundImage:
                'radial-gradient(circle, white 0%, #f2f2f2 100%)',
            }}
            className="w-full"
            ref={comp}
          >
            <div className="flex w-full justify-start flex-col items-center session-start">
              <div className="flex  w-full justify-start flex-1">
                <div className="title-hero">
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      // transform: 'translate(-50%, 0%)',
                    }}
                    className="session-hero"
                  >
                    <div className="flex flex-col flex-1 justify-center">
                      <div className="large-title">
                        <span className="text-gradient">PRODUCTS</span>
                      </div>
                      <div className="large-title">
                        <span>DASHBOARD</span>
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '1.5em',
                          fontWeight: 400,
                          color: '#004bc8',
                          userSelect: 'none',
                          zIndex: -10000,
                        }}
                      >
                        <p className="mb-0 max-w-full">{t('overview1')}</p>
                        <p className="mb-0 max-w-full">{t('overview2')}</p>
                      </div>
                      <div
                        className="mt-10 text-[#004bc8] flex cursor-pointer"
                        style={{ fontWeight: 900 }}
                        onClick={() =>
                          window.open(
                            'https://vn-cubesystem.com/',
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }
                      >
                        <span className="go-home-btn fromLeft">
                          {t('go_home_page')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hero-content absolute">
                  <Scene />
                </div>
              </div>
            </div>
            <div className="flex w-full bg-[#004bc8] h-[120px] items-center pl-[5%] lets-decover">
              <h2
                className="text-white"
                style={{ zIndex: 900, fontSize: '1.4em' }}
              >
                {t('lets_discover')}
              </h2>
              <div
                className="md:w-32 lg:w-48 mr-[24px]"
                style={{ zIndex: 900 }}
              >
                <MouseScroll
                  style={{
                    height: '80%',

                    marginLeft: 20,
                  }}
                  onClick={() =>
                    productsRef!.current!.scrollIntoView({ behavior: 'smooth' })
                  }
                />
              </div>
            </div>
            <div
              className="w-ful z-10 relative main-product overflow-x-hidden"
              style={{
                backgroundColor: '#fff5',
                zIndex: 20,
                // backdropFilter: 'blur(20px)',
              }}
              ref={productsRef}
            >
              <div className="h-[52px]" />
              <div
                className="implemented-content w-full pt-10"
                ref={pro}
                style={{}}
              >
                <div className="flex flex-col justify-center items-center implemented-title mb-6">
                  <h2> {t('being_implemented')}</h2>
                  <div
                    style={{
                      textAlign: 'left',
                      fontSize: '1.2em',
                      fontWeight: 400,
                      color: '#000',
                      userSelect: 'none',
                    }}
                  >
                    <p className="mb-0 max-w-full text-center">
                      {t('implemented_desc')}
                    </p>
                  </div>
                </div>
                <div className="flex justify-center items-center flex-wrap pro-list prolist-1">
                  {implementingProductsRender()}
                </div>
              </div>
              <div style={{}} className="w-full mt-10">
                <div className="flex flex-col justify-center items-center implemented-title mb-6">
                  <h2>{t('ready_demo')}</h2>
                  <div
                    style={{
                      textAlign: 'left',
                      fontSize: '1.2em',
                      fontWeight: 400,
                      color: '#000',
                      userSelect: 'none',
                    }}
                  >
                    <p className="mb-0 max-w-full text-center">
                      {t('demo_desc')}
                    </p>
                  </div>
                </div>
                <div className="flex justify-start items-center flex-wrap pro-list overflow-visible prolist-2">
                  {demoProductsRender()}
                </div>
              </div>
              <div className="h-[100px]"></div>

              <MarqueeFrameworks />
            </div>
            <footer className="text-center w-full relative ">
              {/* <div className="h-[160px] flex items-center bg-[#000] px-[10%] bottom-bg"></div> */}
              {/* <div className="h-[200px] flex items-center bg-[#fff] px-[5%]">
              <img
                src="assets/images/csv_logo.svg"
                color="#aaa"
                height={100}
                style={{ height: 80 }}
              />
            </div> */}
              <div className="footer-content bg-[white] w-full flex justify-end items-center px-10">
                <span className=" font-bold">Product Dashboard v2-1.0.17 </span>
                <span className="mx-4 f-space">|</span>
                <span className="">CUBE SYSTEM VIETNAM CO.,LTD. © 2024</span>
              </div>
            </footer>
          </div>
        </Layout>
      </>
    );
  else return <></>;
};

const listFrameworks = [
  <ReactLogo height={64} width={64} fill="#698add" />,

  <GolangLogo height={64} width={64} fill="#698add" />,

  <FlutterLogo height={64} width={64} fill="#698add" />,

  <VueLogo height={64} width={64} fill="#698add" />,

  <NextCloudLogo height={64} width={64} fill="#698add" />,

  <WebRTCLogo height={64} width={64} fill="#698add" />,

  <AwsLogo height={64} width={64} fill="#698add" />,

  <NodejsLogo height={64} width={64} fill="#698add" />,

  <SwiftLogo height={64} width={64} fill="#698add" />,

  <KotlinLogo height={64} width={64} fill="#698add" />,

  <AndroidLogo height={64} width={64} fill="#698add" />,

  <IosLogo height={64} width={64} fill="#698add" />,

  <PythonLogo height={64} width={64} fill="#698add" />,

  <HtmlLogo height={64} width={64} fill="#698add" />,

  <CssLogo height={64} width={64} fill="#698add" />,

  <MysqlLogo height={64} width={64} fill="#698add" />,

  <PostgreLogo height={64} width={64} fill="#698add" />,
];
export const MarqueeFrameworks = () => {
  return (
    <div className="marquee-wrapper">
      <div className="container">
        <div className="marquee-block">
          <div className="marquee-inner to-left">
            <span>
              {listFrameworks.map((framework, index) => {
                return (
                  <div key={index + 'marquee1'} className="marquee-item">
                    {framework}
                  </div>
                );
              })}
            </span>
            <span>
              {listFrameworks.map((framework, index) => {
                return (
                  <div key={index + 'marquee2'} className="marquee-item">
                    {framework}
                  </div>
                );
              })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
